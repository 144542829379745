export default {
  data () {
    return {
      dataMixinTaskFields: [
        {
          label: '',
          key: 'isBlocked',
          tdClass: 'call-tabel-icon',
          thClass: 'call-tabel-icon'
        },
        {
          label: 'Player ID',
          key: 'player_id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Operator',
          key: 'operator_name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th'
        },
        {
          label: 'Player Name',
          key: 'player_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        // {
        //   label: 'Birthday',
        //   key: 'player_birthday',
        //   sortable: true,
        //   tdClass: 'call-td',
        //   thClass: 'call-th'
        // },
        {
          label: 'Scheduled',
          key: 'execute_before',
          sortable: true,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'auto_closed_free',
          key: 'auto_closed_free',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'auto_closed_assigned',
          key: 'auto_closed_assigned',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'KPI',
          key: 'kpi',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Comments',
          key: 'comment_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Created at',
          key: 'created_at',
          sortable: true,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action--tasks call-tabel-action',
          thClass: 'call-tabel-action--tasks'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
