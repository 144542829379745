<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsTeamLeader || computedIsAdmin"
      placement="top"
      :content="!operator ? 'Re-Assign' : 'Assign'">
      <call-button
        v-if="closed"
        outlet
        type="warning"
        round
        not-border
        :icon="dataIconUser"
        @click="clickAction('re-asigned')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsTeamLeader || computedIsAdmin"
      placement="top"
      :content="!time ? 'Re-Schedule' : 'Schedule'">
      <call-button
        v-if="closed"
        outlet
        type="danger"
        round
        not-border
        :icon="dataIconClock"
        @click="clickAction('re-sheduled')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsTeamLeader || computedIsAdmin"
      placement="top"
      content="Closed Task">
      <call-button
        v-if="closed"
        outlet
        type="success"
        round
        not-border
        :icon="dataIconCheck"
        @click="clickAction('closed')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faCheck, faClock, faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';

export default {
  name: 'CallTableTasksActions',
  components: { CallButton },
  props: {
    closed: {
      type: Boolean,
      default: false
    },
    operator: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsTeamLeader: 'auth/isModerator'
    })
  },
  data () {
    return {
      dataViewIcon: faEye,
      dataIconUser: faUser,
      dataIconClock: faClock,
      dataIconCheck: faCheck
    };
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>
